import PropTypes from 'prop-types';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import '../../assets/css/bootstrap.css';
import GlobalStyle from '../../assets/css/mainStyle';
import '../../assets/fonts/cerebrisans/cerebrisans.css';
import CookieConsentMessage from '../../components/CookieConsentMessage';
import Transition from '../../components/Transition';
import ScrollToTop from '../../components/ui/ScrollToTop';
import { theme } from '../../theme';

const REGEX_ENABLE_RECAPTCHA = /(\/((ouvidoria)|(fale-conosco)|(bolsao)|(segunda-via-boleto)|(evento\/*)))/i;
const reCaptchaKey = process.env.GATSBY_RECAPTCHA_KEY;

const LayoutContent = ({ location, children }) => (
  <ThemeProvider theme={theme}>
    <Transition location={location}>
      <div className="wrapper">
        <GlobalStyle />
        {children}
        <ScrollToTop />
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
      <CookieConsentMessage />
    </Transition>
  </ThemeProvider>
);

const Layout = ({ children, location }) => {
  return REGEX_ENABLE_RECAPTCHA.test(location.pathname) ? (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language="pt-BR">
      <LayoutContent location={location}>{children}</LayoutContent>
    </GoogleReCaptchaProvider>
  ) : (
    <LayoutContent location={location}>{children}</LayoutContent>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

LayoutContent.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
