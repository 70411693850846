// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nossos-cursos-js": () => import("./../../../src/pages/nossos-cursos.js" /* webpackChunkName: "component---src-pages-nossos-cursos-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-course-template-course-template-js": () => import("./../../../src/templates/course-template/course-template.js" /* webpackChunkName: "component---src-templates-course-template-course-template-js" */),
  "component---src-templates-leads-capture-template-leads-capture-template-js": () => import("./../../../src/templates/leads-capture-template/leads-capture-template.js" /* webpackChunkName: "component---src-templates-leads-capture-template-leads-capture-template-js" */),
  "component---src-templates-news-list-news-list-js": () => import("./../../../src/templates/news-list/news-list.js" /* webpackChunkName: "component---src-templates-news-list-news-list-js" */),
  "component---src-templates-news-template-news-template-js": () => import("./../../../src/templates/news-template/news-template.js" /* webpackChunkName: "component---src-templates-news-template-news-template-js" */),
  "component---src-templates-sales-page-template-sales-page-template-js": () => import("./../../../src/templates/sales-page-template/sales-page-template.js" /* webpackChunkName: "component---src-templates-sales-page-template-sales-page-template-js" */),
  "component---src-templates-terms-template-terms-template-js": () => import("./../../../src/templates/terms-template/terms-template.js" /* webpackChunkName: "component---src-templates-terms-template-terms-template-js" */)
}

