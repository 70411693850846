export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setTimeout(() => {
      const hash = location.hash.substring(1);
      const elementById = document.getElementById(hash);
      const linkElement = document.querySelector(`[href="#${hash}"]`);
      const targetElement = elementById || linkElement?.closest('h3, h2, h1, section');
      if (targetElement) {
        const fixedHeader = document.querySelector('header .FixedHeader');
        const simpleHeader = document.querySelector('.SimpleHeader');
        let menuHeight = 0;
        if (fixedHeader) {
          menuHeight = fixedHeader.offsetHeight;
        } else if (simpleHeader) {
          menuHeight = simpleHeader.offsetHeight || 60;
        }
        const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPosition - menuHeight - 10,
          behavior: 'smooth',
        });
      }
    }, 0);
  }
};
